import i18n from '@/i18n'

export enum ProjectStep {
  concept = 'concept-submission',
  winner = 'offer-selection',
  revision = 'contract-submission',
  completion = 'completion',
}

export const ProjectStepData: any = {
  [ProjectStep.concept]: i18n.t('Concept submission'),
  [ProjectStep.winner]: i18n.t('Offer submission'),
  [ProjectStep.revision]: i18n.t('Revision'),
  [ProjectStep.completion]: i18n.t('Completion'),
}
